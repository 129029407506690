import React, { createContext, useReducer, ReactNode, useEffect } from "react";
import { IConfirm } from "../hooks/useConfirm";

type ConfirmAction =
  | { type: "SET_MODAL"; payload: boolean }
  | { type: "SET_OK"; payload: string }
  | { type: "SET_CANCEL"; payload: string }
  | { type: "SET_TYPE"; payload: "danger" | "success" }
  | { type: "SET_TITLE"; payload: string }
  | { type: "SET_SUBTITLE"; payload: string }
  | {
      type: "COLOR";
      payload: "green" | "blue" | "purple" | "red" | "black" | "";
    };

const confirmReducer = (state: IConfirm, action: ConfirmAction): IConfirm => {
  switch (action.type) {
    case "SET_MODAL":
      return { ...state, modal: action.payload };
    case "SET_OK":
      return { ...state, ok: action.payload };
    case "SET_CANCEL":
      return { ...state, cancel: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "SET_SUBTITLE":
      return { ...state, subtitle: action.payload };
    case "COLOR":
      return { ...state, color: action.payload };
    default:
      return state;
  }
};

const initialState: IConfirm = {
  modal: false,
  ok: "",
  cancel: "",
  type: "danger",
  title: "",
  subtitle: "",
  color: "",
};

export const ConfirmContext = createContext<{
  state: IConfirm;
  dispatch: React.Dispatch<ConfirmAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ConfirmProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(confirmReducer, initialState);
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch({ type: "SET_MODAL", payload: false });
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <ConfirmContext.Provider value={{ state, dispatch }}>
      {children}
    </ConfirmContext.Provider>
  );
};
