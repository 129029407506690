import React, { FC, ReactNode } from "react";
import "./style.scss";

interface TitleProps {
  level?: "1" | "2" | "3" | "4";
  children: ReactNode;
  className?: string;
}

const TextCaption: FC<TitleProps> = ({
  level = "large",
  children,
  className = "",
}) => {
  return <div className={`caption-${level} ${className}`}>{children}</div>;
};

export default TextCaption;
