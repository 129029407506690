import axiosInstance from "../../axios";
import { TFeedbackForm } from "./types";
import { v4 as uuidv4 } from "uuid";

export const feedbacks = {
  create: async (data: TFeedbackForm) => {
    try {
      const response = await axiosInstance.post(`/forms/create`, {
        ...data,
      });

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  update: async (data: TFeedbackForm, formId: undefined | string | number) => {
    try {
      const response = await axiosInstance.put(`/forms/edit/${formId}`, {
        ...data,
      });

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  find: async (formId: number | string | undefined): Promise<TFeedbackForm> => {
    try {
      const { data } = await axiosInstance.get<{ data: TFeedbackForm }>(
        `/forms/find/${formId}`,
      );
      const questions = data.data.questions.map(item => {
        return {
          ...item,
          uid: uuidv4(),
          answers: item.answers?.map(answer => {
            return {
              ...answer,
              uid: uuidv4(),
            };
          }),
        };
      });
      return {
        ...data.data,
        questions,
      };
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  list: async (isActive: boolean) => {
    try {
      const response = await axiosInstance.get(
        `/forms/list?isActive=${isActive}`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  deactivate: async (formId: number) => {
    try {
      const response = await axiosInstance.patch(`/forms/update/${formId}`, {
        isActive: false,
      });

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  reactivate: async (formId: number) => {
    try {
      const response = await axiosInstance.patch(`/forms/update/${formId}`, {
        isActive: true,
      });

      return response.data;
    } catch (error) {
      console.error("Deactivate error:", error);
      throw error;
    }
  },
  remove: async (formId: number) => {
    try {
      const response = await axiosInstance.delete(`/forms/delete/${formId}`);

      return response.data;
    } catch (error) {
      console.error("Remove error:", error);
      throw error;
    }
  },
  listQuestionnaires: async (formId: number, page: number) => {
    try {
      const response = await axiosInstance.get(
        `questionnaires/list?formId=${formId}&perPage=10&page=${page}`,
      );
      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  listQuestionnairesCompany: async (id: number) => {
    try {
      const response = await axiosInstance.get(`surveys/list/${id}`);

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  listStatistics: async (formId: number) => {
    try {
      const response = await axiosInstance.get(
        `/questions/statistics?formId=${formId}`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  listAnswers: async (questionId: number, page: number) => {
    try {
      const response = await axiosInstance.get(
        `/surveys/list?questionId=${questionId}&perPage=10&page=${page}&isOther=true`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
};
