import { useQuery } from "react-query";
import { feedbacks } from "../../api/feedbacks";

export const useFindForm = (formId: number | string | undefined) => {
  return useQuery(["feedback-form", formId], () => feedbacks.find(formId), {
    enabled: !!formId,
    staleTime: 0,
    cacheTime: 0,
  });
};
