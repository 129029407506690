import React, { Ref, forwardRef, useEffect, useRef } from "react";
import "./style.scss";
import TextTitle from "../common/ui/typography/TextTitle";
import Button from "../common/ui/buttons/Button";
import useStore from "./store";
import Icon from "../common/ui/Icon";
import TextCaption from "../common/ui/typography/TextCaption";
import ButtonGroupTab from "../common/ui/buttonGroupTab/ButtonGroupTab";
import DatePicker from "react-datepicker";
import { TIconsNames } from "../common/iconSets";
import DatePickerInput from "../common/form/DatePickerInput";
import CompaniesStatistics from "./components/companiesStatistics/CompaniesStatistics";
const OverviewPage = () => {
  const activeOption = useStore(state => state.activeOption);
  const setActiveOption = useStore(state => state.setActiveOption);
  const startDate = useStore(state => state.startDate);
  const endDate = useStore(state => state.endDate);
  const setStartDate = useStore(state => state.setStartDate);
  const setEndDate = useStore(state => state.setEndDate);
  const resetStore = useStore(state => state.resetStore);
  const inputRef = useRef(null);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, [resetStore]);
  const handleOptionSelect = (selectedOption: {
    title: string;
    value: string;
  }) => {
    setActiveOption(selectedOption);
  };
  const tabs = [
    { title: "Day", value: "day" },
    { title: "Week", value: "week" },
    { title: "Month", value: "month" },
    { title: "Year", value: "year" },
    { title: "Custom time range", value: "custom" },
  ];
  return (
    <div className='overview'>
      <div className='overview__header'>
        <TextTitle level='large'>Today, 17 July</TextTitle>
        <Button beforeIcon='arrow-clockwise'>Refresh</Button>
      </div>
      <div className='overview__body'>
        <div className='overview__body__infocpm'>
          <div className='overview__card'>
            <div className='overview__card__header'>
              <TextTitle level='2'>Total Companies</TextTitle>
              <Icon icon='info' />
            </div>
            <div className='overview__card__body'>
              <TextTitle level='large'>1,632</TextTitle>
              <div className='d-flex gap-1'>
                <TextCaption level='2'>+24 (0.15%) </TextCaption>
                <TextCaption level='4'> more than yesterday</TextCaption>
              </div>
            </div>
          </div>
          <div className='overview__card'>
            <div className='overview__card__header'>
              <TextTitle level='2'>Total Vehicles</TextTitle>
              <Icon icon='info' />
            </div>
            <div className='overview__card__body'>
              <TextTitle level='large'>12,498</TextTitle>
              <div className='d-flex gap-1'>
                <TextCaption level='2'>+24 (0.15%) </TextCaption>
                <TextCaption level='4'> more than yesterday</TextCaption>
              </div>
            </div>
          </div>
          <div className='overview__card'>
            <div className='overview__card__header'>
              <TextTitle level='2'>Total Drivers</TextTitle>
              <Icon icon='info' />
            </div>
            <div className='overview__card__body'>
              <TextTitle level='large'>1,632</TextTitle>
              <div className='d-flex gap-1 flex-wrap'>
                <TextCaption level='2'>+24 (0.15%) </TextCaption>
                <TextCaption level='4'> more than yesterday</TextCaption>
              </div>
            </div>
          </div>
        </div>
        <div className='overview__body__fmetrics'>
          <div className='overview__body__fmetrics--header'>
            <TextTitle level='2'>Financial Metrics</TextTitle>
            <Icon icon='info' />
          </div>
          <div className='d-flex w-100 gap-3 flex-wrap'>
            <div className='overview__body__fmetrics__info'>
              <TextCaption level='4'>Daily Recurring Revenue (DRR)</TextCaption>
              <TextTitle level='1'>$000,000.00</TextTitle>
            </div>
            <div className='overview__body__fmetrics__info'>
              <TextCaption level='4'>
                Monthly Recurring Revenue (MRR)
              </TextCaption>
              <TextTitle level='1'>$000,000.00</TextTitle>
            </div>
            <div className='overview__body__fmetrics__info'>
              <TextCaption level='4'>
                Annual Recurring Revenue (ARR)
              </TextCaption>
              <TextTitle level='1'>$000,000.00</TextTitle>
            </div>
          </div>
        </div>
      </div>
      <div className='overview__statistics'>
        <TextTitle level='1'>Statistics</TextTitle>
        <div className='d-flex gap-3 align-items-center'>
          {activeOption && (
            <ButtonGroupTab
              options={tabs}
              defaultActive={activeOption}
              onOptionSelect={handleOptionSelect}
            />
          )}
          {activeOption?.value === "custom" && (
            <div className='date-pickers d-flex'>
              <DatePickerInput
                value={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                dateFormat='MMM d, yyyy'
                customClassName='date-picker-input custom-datepicker'
                maxDate={endDate || new Date()}
                placeholder='From'
                afterIcon='chevron-down'
                beforeIcon='calendar'
              />
              <DatePickerInput
                value={endDate}
                onChange={date => setEndDate(date)}
                dateFormat='MMM d, yyyy'
                customClassName='date-picker-input custom-datepicker'
                maxDate={new Date()}
                minDate={startDate || undefined}
                placeholder='To'
                afterIcon='chevron-down'
                beforeIcon='calendar'
              />
            </div>
          )}
        </div>
        <CompaniesStatistics />
      </div>
    </div>
  );
};

export default OverviewPage;
