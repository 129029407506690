import { useState } from "react";
import axiosInstance from "../../axios";

interface LoginCredentials {
  email: string;
  password: string;
}

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const login = async (credentials: LoginCredentials) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.post(
        "/auths/login/super-admin",
        credentials,
      );
      const { id } = response.data;
      localStorage.setItem("accessToken", id);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      console.error("Login error:", error);
    }
  };

  return { login, loading, error };
};

export default useLogin;
