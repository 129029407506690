import React from "react";
import "./style.scss";
import TextCaption from "../typography/TextCaption";
import TextTitle from "../typography/TextTitle";

interface IInfoCard {
  color?: "green" | "orange" | "blue" | "red";
  background?: string;
  title?: string;
  amount?: string;
  percent?: string;
  percentText?: string;
}
const InfoCard = ({
  color,
  background,
  title,
  amount,
  percent,
  percentText,
}: IInfoCard) => {
  return (
    <div className='infoCard' style={{ background }}>
      <div className='infoCard__title'>
        <div
          className={`infoCard__title--indicator infoCard__title--${color}`}
        ></div>
        <TextCaption level='3'>{title}</TextCaption>
      </div>
      <TextTitle level='1'>{amount}</TextTitle>
      <div>
        <TextCaption level='2'>{percent}</TextCaption>
        <TextCaption level='3'>{percentText}</TextCaption>
      </div>
    </div>
  );
};

export default InfoCard;
