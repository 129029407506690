import React, { useCallback } from "react";
import "./style.scss";
import ProgressBar from "../../../common/ui/progressBar";
import { useFeedbacksStatistics } from "../../../../hooks/feedbacks/useFeedbacksStatistics";
import TextTitle from "../../../common/ui/typography/TextTitle";
import Loader from "../../../common/ui/loader/Loader";
import TextBody from "../../../common/ui/typography/TextBody";
import Button from "../../../common/ui/buttons/Button";

const CompanyFeedbacksStatistics = ({
  formId,
  setAnswerInput,
}: {
  formId: number;
  setAnswerInput: ({
    questionId,
    title,
  }: {
    questionId: number;
    title: string;
  }) => void;
}) => {
  const { data, isLoading } = useFeedbacksStatistics(formId);
  const renderField = useCallback((field: any, i: number) => {
    switch (field.answerType) {
      case "checkbox":
        return (
          <div key={i} className='d-flex flex-column gap-3 answer'>
            <div>
              <TextTitle className='' level='1'>
                {field.title}{" "}
                {!field.isOptional && (
                  <>
                    * <div className={"required"}>(Required)</div>
                  </>
                )}
              </TextTitle>
              <ProgressBar
                percentage={field.totalPercentage}
                color={"blue"}
                beforeText={`${field.totalPercentage}% (${field.totalAnswers}) answered`}
                beforeLevel={"2"}
              />
            </div>
            <div className={"d-flex flex-row"} style={{ gap: "24px" }}>
              <div className='d-flex flex-column' style={{ gap: "12px" }}>
                {field.answers?.map((option: any, index: number) => (
                  <div className={"d-flex"} key={index}>
                    <span
                      className={`progress-bar-text ${option.totalPercentage === 0 ? "grey-bar_text" : "red-bar_text"}`}
                    >
                      <TextBody className={""} level={"2"}>
                        {option.totalPercentage}% ({option.totalAnswerCount})
                      </TextBody>
                    </span>
                  </div>
                ))}
              </div>
              <div className='d-flex flex-column' style={{ gap: "12px" }}>
                {field.answers?.map((option: any, index: number) => (
                  <div
                    className={"d-flex"}
                    key={index}
                    style={{ height: 20, gap: "24px" }}
                  >
                    <ProgressBar
                      percentage={option.totalPercentage}
                      color={"red"}
                    />
                    <TextBody className={""} level={"1"}>
                      {option.title === "other" ? "Other" : option.title}
                    </TextBody>
                  </div>
                ))}
              </div>
            </div>
            {field.answers[field.answers.length - 1].title === "other" && (
              <>
                <div>
                  <Button
                    size={"xs"}
                    afterIcon={"chevron-right"}
                    onClick={() => {
                      setAnswerInput({
                        questionId: field.id,
                        title: field.title,
                      });
                    }}
                  >
                    See all answers for “Others”
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      case "radio":
        return (
          <div key={i} className={`d-flex flex-column gap-3 answer`}>
            <div>
              <TextTitle className='' level='1'>
                {field.title}{" "}
                {!field.isOptional && (
                  <>
                    * <div className={"required"}>(Required)</div>
                  </>
                )}
              </TextTitle>
              <ProgressBar
                percentage={field.totalPercentage}
                color={"blue"}
                beforeText={`${field.totalPercentage}% (${field.totalAnswers}) answered`}
                beforeLevel={"2"}
              />
            </div>
            <div className={"d-flex flex-row"} style={{ gap: "24px" }}>
              <div className='d-flex flex-column' style={{ gap: "12px" }}>
                {field.answers?.map((option: any, index: number) => (
                  <div className={"d-flex"} key={index}>
                    <span
                      className={`progress-bar-text ${option.totalPercentage === 0 ? "grey-bar_text" : "red-bar_text"}`}
                    >
                      <TextBody className={""} level={"2"}>
                        {option.totalPercentage}% ({option.totalAnswerCount})
                      </TextBody>
                    </span>
                  </div>
                ))}
              </div>
              <div className='d-flex flex-column' style={{ gap: "12px" }}>
                {field.answers?.map((option: any, index: number) => (
                  <div
                    className={"d-flex"}
                    key={index}
                    style={{ height: 20, gap: "24px" }}
                  >
                    <ProgressBar
                      percentage={option.totalPercentage}
                      color={"red"}
                    />
                    <TextBody className={""} level={"1"}>
                      {option.title === "other" ? "Other" : option.title}
                    </TextBody>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      case "input":
        return (
          <div key={i} className='d-flex flex-column gap-3 answer'>
            <div>
              <TextTitle className='' level='1'>
                {field.title}{" "}
                {!field.isOptional && (
                  <>
                    * <div className={"required"}>(Required)</div>
                  </>
                )}
              </TextTitle>
              <ProgressBar
                percentage={field.totalPercentage}
                color={"blue"}
                beforeText={`${field.totalPercentage}% (${field.totalAnswers}) answered`}
                beforeLevel={"2"}
              />
            </div>
            <div>
              <Button
                size={"xs"}
                afterIcon={"chevron-right"}
                onClick={() => {
                  setAnswerInput({ questionId: field.id, title: field.title });
                }}
              >
                See all answers
              </Button>
            </div>
          </div>
        );
      case "textarea":
        return (
          <div key={i} className='d-flex flex-column gap-3 answer'>
            <div>
              <TextTitle className='' level='1'>
                {field.title}{" "}
                {!field.isOptional && (
                  <>
                    * <div className={"required"}>(Required)</div>
                  </>
                )}
              </TextTitle>
              <ProgressBar
                percentage={field.totalPercentage}
                color={"blue"}
                beforeText={`${field.totalPercentage}% (${field.totalAnswers}) answered`}
                beforeLevel={"2"}
              />
            </div>
            <div>
              <Button
                size={"xs"}
                afterIcon={"chevron-right"}
                onClick={() => {
                  setAnswerInput({ questionId: field.id, title: field.title });
                }}
              >
                See all answers
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }, []);
  return (
    <div className={"statistics"}>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className='dynamic-form d-flex flex-column'
          style={{ marginTop: 16 }}
        >
          {data?.data?.map(renderField)}
        </div>
      )}
    </div>
  );
};

export default CompanyFeedbacksStatistics;
