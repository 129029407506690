import React, { useCallback } from "react";
import templates from "../../templates.json";
import TextTitle from "../../../../../common/ui/typography/TextTitle";
import Checkbox from "../../../../../common/form/Checkbox";
import TextInput from "../../../../../common/form/TextInput";
import { Radio } from "../../../../../common/form/Radio";
import TextArea from "../../../../../common/form/Textarea";
import "./style.scss";
import Button from "../../../../../common/ui/buttons/Button";
const FeedbackTemplates = ({
  chooseTemplate,
}: {
  chooseTemplate: (i: number) => void;
}) => {
  return (
    <div className='templates'>
      {templates?.map((field: any, i: number) => {
        switch (field.answerType) {
          case "checkbox":
            return (
              <div
                key={i}
                className='d-flex flex-column gap-3 templates__template'
              >
                <TextTitle className='' level='1'>
                  {field.title}
                </TextTitle>

                <div className='d-flex flex-column' style={{ gap: "12px" }}>
                  {field.answers?.map((option: any, index: number) => (
                    <React.Fragment key={index}>
                      <Checkbox
                        key={index}
                        modelValue={option.isMarked}
                        label={option.title}
                        disabled={!option.isMarked}
                      />
                    </React.Fragment>
                  ))}
                  {field.answerType && (
                    <React.Fragment key={"other"}>
                      <Checkbox
                        modelValue={false}
                        label='Other'
                        disabled={true}
                      />
                      <div style={{ marginLeft: 30, width: "240px" }}>
                        <TextInput type='text' placeholder='Type here...' />
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <Button main onClick={() => chooseTemplate(i)}>
                  Add
                </Button>
              </div>
            );
          case "radio":
            return (
              <div
                key={i}
                className={`d-flex flex-column gap-3 templates__template`}
              >
                <TextTitle className='' level='1'>
                  {field.title}{" "}
                </TextTitle>
                <div
                  className={`d-flex ${field.isHorizontal ? "flex-row" : "flex-column"}`}
                  style={{ gap: "12px" }}
                >
                  {field.answers?.map((option: any, index: number) => (
                    <Radio
                      key={index}
                      option={{ value: option.title, label: option.title }}
                      value={option.isMarked ? option.title : ""}
                      disabled={!option.isMarked}
                    />
                  ))}
                </div>
                <Button main onClick={() => chooseTemplate(i)}>
                  Add
                </Button>
              </div>
            );
          case "input":
            return (
              <div
                key={i}
                className='d-flex flex-column gap-3 templates__template'
              >
                <TextTitle className='' level='1'>
                  {field.title}{" "}
                </TextTitle>
                <TextInput
                  type='text'
                  value={field.note || ""}
                  placeholder='Type here...'
                />
                <Button main onClick={() => chooseTemplate(i)}>
                  Add
                </Button>
              </div>
            );
          case "textarea":
            return (
              <div
                key={i}
                className='d-flex flex-column gap-3 templates__template'
              >
                <TextTitle className='' level='1'>
                  {field.title}{" "}
                </TextTitle>
                <TextArea
                  type='text'
                  modelValue={field.note || ""}
                  placeholder='Type here...'
                />
                <Button main onClick={() => chooseTemplate(i)}>
                  Add
                </Button>
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default FeedbackTemplates;
