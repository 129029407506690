import { useQuery } from "react-query";
import { feedbacks } from "../../api/feedbacks";

export const useFeedbackCompanyAnswer = (id: number) => {
  return useQuery(
    ["feedback-company-answer", id],
    () => feedbacks.listQuestionnairesCompany(id),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
