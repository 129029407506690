import React, { useContext } from "react";
import { ConfirmContext } from "../providers/ConfirmProvider";

export interface IConfirm {
  modal: boolean;
  ok: string;
  cancel: string;
  type: "danger" | "success";
  title: string;
  subtitle: string;
  color: "green" | "blue" | "purple" | "red" | "black" | "";
}

export const useConfirm = () => {
  const { state, dispatch } = useContext(ConfirmContext);

  const confirm = (
    {
      title,
      ok,
      cancel,
      type,
      subtitle,
      color,
    }: Partial<Omit<IConfirm, "modal">> = {
      ok: "Yes",
      cancel: "Cancel",
      type: "danger",
      title: "Are you sure?",
      subtitle: "",
    },
  ) => {
    dispatch({ type: "SET_MODAL", payload: true });
    dispatch({ type: "SET_OK", payload: ok || "Yes" });
    dispatch({ type: "SET_CANCEL", payload: cancel || "Cancel" });
    dispatch({ type: "SET_TYPE", payload: type || "danger" });
    dispatch({ type: "SET_TITLE", payload: title || "" });
    dispatch({ type: "SET_SUBTITLE", payload: subtitle || "" });
    dispatch({ type: "COLOR", payload: color || "" });

    return new Promise<boolean>(resolve => {
      const handleCancel = () => {
        resolve(false);
        dispatch({ type: "SET_MODAL", payload: false });
      };

      const handleConfirm = () => {
        resolve(true);
        dispatch({ type: "SET_MODAL", payload: false });
      };

      setTimeout(() => {
        const btnCancel = document.getElementById("confirm-cancel");
        const btnConfirm = document.getElementById("confirm-yes");

        if (btnCancel) {
          btnCancel.addEventListener("click", handleCancel);
        }

        if (btnConfirm) {
          btnConfirm.addEventListener("click", handleConfirm);
        }
      }, 0);
    });
  };

  return {
    state,
    confirm,
  };
};
