import React, { useState } from "react";
import TextInput from "./TextInput";
import { TIconsNames } from "../iconSets";
import { TextInputProps } from "./types"; // adjust the import according to your file structure

interface PasswordInputProps {
  autocomplete?: string;
  showPassword: boolean;
}

export const PasswordInput: React.FC<
  PasswordInputProps | TextInputProps
> = props => {
  const [showPassword, setShowPassword] = useState(false);
  const onClickAfter = (): void => {
    setShowPassword(!showPassword);
  };
  return (
    <TextInput
      type={showPassword ? "text" : "password"}
      beforeIcon='lock'
      afterIcon={showPassword ? "eye-hide" : "eye-show"}
      onClickAfter={onClickAfter}
      {...props}
    />
  );
};
