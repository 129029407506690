import React from "react";
import styles from "./style.module.css";
import dock_left from "./../../common/icons/dock_left.svg";
import { NavLink } from "react-router-dom";
import Icon from "../../common/ui/Icon";
import tteld from "./../../../assets/images/tteld.svg";
import evoeld from "./../../../assets/images/evoeld.svg";
import ontime from "./../../../assets/images/ontime.svg";
import zippyeld from "./../../../assets/images/zippyeld.svg";
const Sidebar = () => {
  const getLogo = () => {
    if (String(process.env.REACT_APP_BASEURL).includes("tteld")) {
      return tteld;
    }
    if (String(process.env.REACT_APP_BASEURL).includes("evoeld")) {
      return evoeld;
    }
    if (String(process.env.REACT_APP_BASEURL).includes("ontime")) {
      return ontime;
    }
    if (String(process.env.REACT_APP_BASEURL).includes("zippyeld")) {
      return zippyeld;
    }
    return tteld;
  };
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarHeader}>
        <img src={getLogo()} alt='logo' />
        <img
          src={dock_left}
          alt='dock left'
          style={{ cursor: "pointer", marginLeft: "auto" }}
        />
      </div>
      <div className={styles.sidebarMenu}>
        <div className={styles.sidebarSubMenu}>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? styles.sidebarSubMenuHeadActive
                : styles.sidebarSubMenuHead
            }
            to={"/"}
          >
            {({ isActive }) => (
              <>
                <Icon
                  icon={isActive ? "view-desktop-filled" : "view-desktop"}
                />
                Overview
              </>
            )}
          </NavLink>
        </div>
      </div>

      <div className={styles.sidebarMenu}>
        <div className={styles.sidebarMenuHead}>Main</div>
        <div className={styles.sidebarSubMenu}>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? styles.sidebarSubMenuHeadActive
                : styles.sidebarSubMenuHead
            }
            to={"/feedbacks"}
          >
            {({ isActive }) => (
              <>
                <Icon icon={isActive ? "comment-filled" : "comment"} />
                Feedbacks
              </>
            )}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
