import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./style.scss";

const CustomYTick: React.FC<any> = ({ x, y, payload, ...props }) => {
  return (
    <text x={x - 10} y={y + 3} {...props}>
      {payload.value}
    </text>
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${payload[0].name} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

interface ChartProps {
  data: any[];
  lines: { key: string; color: string }[];
  yAxisTicks: number[];
}

const Chart: React.FC<ChartProps> = ({ data, lines, yAxisTicks }) => {
  return (
    <ResponsiveContainer width='100%' height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis
          ticks={yAxisTicks}
          tickFormatter={value => `${value}`}
          tick={<CustomYTick />}
        />
        <Tooltip content={<CustomTooltip />} />
        {lines.map((line, index) => (
          <Line
            key={index}
            type='linear'
            dataKey={line.key}
            stroke={line.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
