import React from "react";
import "./style.scss";
import TextTitle from "../../../../../common/ui/typography/TextTitle";
import Icon from "../../../../../common/ui/Icon";
import TextCaption from "../../../../../common/ui/typography/TextCaption";
import InfoCard from "../../../../../common/ui/infoCard/InfoCard";
import Chart from "../../../../../common/ui/Chart/Chart";

const data = [
  { name: "", series1: 605, series2: 340, highlight: false },
  { name: "February", series1: 509, series2: 435, highlight: true },
  { name: "March", series1: 820, series2: 660, highlight: false },
  { name: "April", series1: 831, series2: 270, highlight: false },
  { name: "May", series1: 546, series2: 665, highlight: false },
  { name: "June", series1: 555, series2: 530, highlight: false },
  { name: "July", series1: 420, series2: 355, highlight: false },
];

const yAxisTicks = [5000, 4000, 3000, 2000, 1000, 500, 0];
const lines = [
  { key: "series1", color: "#8884d8" },
  { key: "series2", color: "#82ca9d" },
];
const SubscriptionQuantity = () => {
  return (
    <div className='staticticsCard'>
      <div className='staticticsCard__header'>
        <TextTitle level='2'>Subscription Quantity</TextTitle>
        <Icon icon='info' />
      </div>
      <div className='staticticsCard__quantity'>
        <TextCaption level='3'>Total quantity</TextCaption>
        <TextTitle level='large'>1,632</TextTitle>
        <div className='d-flex gap-1'>
          <TextCaption level='2'>+24 (0.15%)</TextCaption>
          <TextCaption level='3'>more than yesterday</TextCaption>
        </div>
      </div>
      <div className='d-flex w-100' style={{ gap: "16px" }}>
        <InfoCard
          title='Active companies'
          color='green'
          amount='1,589'
          percent='+24 (0.15%)'
          percentText='more from yesterday'
          // background='rgba(249, 158, 44, 0.10)'
        />
        <InfoCard
          title='Decreases'
          color='orange'
          amount='43'
          percent='-2 (0.5%)'
          percentText='less from yesterday'
          background='rgba(249, 158, 44, 0.10)'
        />
        <InfoCard
          title='Increased Comp-s'
          color='green'
          amount='56'
          percent='0 (0%)'
          percentText='more from yesterday'
          background='rgba(39, 174, 96, 0.10)'
        />
      </div>
      <Chart data={data} lines={lines} yAxisTicks={yAxisTicks} />
    </div>
  );
};

export default SubscriptionQuantity;
