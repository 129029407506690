import { useQuery } from "react-query";
import { feedbacks } from "../../api/feedbacks";

export const useFeedbacksStatistics = (formId: number) => {
  return useQuery(
    ["feedbacks-statistics", formId],
    () => feedbacks.listStatistics(formId),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
