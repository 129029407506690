import React from "react";
import { icons, TIconsNames } from "../iconSets"; // Adjust the path according to your project structure

interface IconComponentProps {
  size?: number | string;
  color?: string;
  icon: TIconsNames;
  className?: string;
}

const Icon: React.FC<IconComponentProps> = ({
  size = "24",
  color = "inherit",
  icon,
  className = "",
}) => {
  return (
    <img
      src={icons[icon]}
      alt={icon}
      style={{ width: size, color }}
      className={className}
    />
  );
};

export default Icon;
