import React from "react";
import "./style.scss";

const Loader: React.FC = () => {
  return (
    <div className='spinner-container'>
      <div className='spinner'></div>
    </div>
  );
};

export default Loader;
