import React, { CSSProperties, ReactNode } from "react";
import { TableColumn } from "./TableTypes";
import "./style.scss";

interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  renderRow?: (
    item: T,
    column: TableColumn<T>,
    rowIndex: number,
    colIndex: number,
  ) => ReactNode;
  isLoading?: boolean;
}

const Table = <T,>({
  columns,
  data,
  renderRow,
  isLoading = false,
}: TableProps<T>): JSX.Element => {
  function createCSSValue(value: string | number) {
    return Number(value) == value ? value + "px" : value;
  }
  function createColumnStyle<T>(
    headerItem: TableColumn<T>,
    zIndex = 4,
  ): CSSProperties {
    const style: CSSProperties = {};

    if (headerItem.align) {
      style.textAlign = headerItem.align;
    }
    if (headerItem.width) {
      style.width = createCSSValue(headerItem.width);
      style.minWidth = createCSSValue(headerItem.width);
    }
    if (headerItem.minWidth) {
      style.minWidth = createCSSValue(headerItem.minWidth);
    }
    if (headerItem.maxWidth) {
      style.maxWidth = createCSSValue(headerItem.maxWidth);
    }

    // if (headerItem.fixed) {
    //     style.zIndex = zIndex;
    // }

    return style;
  }

  return (
    <div className={"tableU tableU--stripe"}>
      <table>
        <thead>
          <tr>
            <th></th>
            {columns.map((column, index) => (
              <th
                key={index}
                className={`tableU__header ${column.class || ""}`}
                style={createColumnStyle(column, 1)}
              >
                {column.header}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={columns.length + 2}>
                <div className='tableU__loading'></div>
              </td>
            </tr>
          )}
          {!isLoading &&
            data?.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td></td>
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className={`tableU__column ${column.cellClass || ""}`}
                    onClick={() => column?.clickRow && column?.clickRow(item)}
                  >
                    {renderRow
                      ? renderRow(item, column, rowIndex, colIndex)
                      : (item[column.accessor] as unknown as ReactNode)}
                  </td>
                ))}
                <td></td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
