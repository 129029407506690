import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface AppState {
  activeOption: { title: string; value: string } | null;
  startDate: Date | null;
  endDate: Date | null;
  setActiveOption: (option: { title: string; value: string }) => void;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  resetStore: () => void;
}

const useStore = create<AppState>()(
  devtools(set => ({
    activeOption: { title: "Week", value: "week" },
    startDate: new Date(),
    endDate: new Date(),
    setActiveOption: (option: { title: string; value: string }) =>
      set({ activeOption: option }),
    setStartDate: (date: Date | null) => set({ startDate: date }),
    setEndDate: (date: Date | null) => set({ endDate: date }),
    resetStore: () =>
      set({
        activeOption: { title: "Week", value: "week" },
        startDate: new Date(),
        endDate: new Date(),
      }),
  })),
);

export default useStore;
