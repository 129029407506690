import React from "react";
import "./style.scss";
import Icon from "../../components/common/ui/Icon";
import TextBody from "../../components/common/ui/typography/TextBody";
import TextTitle from "../../components/common/ui/typography/TextTitle";

type AlertType = "success" | "error" | "warning" | "info";
type IconTypes =
  | "checkmark-circle-filled"
  | "error-circle-filled"
  | "info-filled"
  | "error-triangle-filled";
interface AlertProps {
  type: AlertType;
  title?: string;
  message: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ type, title, message, onClose }) => {
  const getIcon = (icon: string): IconTypes => {
    switch (icon) {
      case "success":
        return "checkmark-circle-filled";
      case "error":
        return "error-circle-filled";
      case "warning":
        return "error-triangle-filled";
      case "info":
        return "info-filled";
      default:
        throw new Error(`Unknown icon type: ${icon}`);
    }
  };
  return (
    <div className={`alert alert-${type}`}>
      <div className='alert-icon'>
        <Icon icon={getIcon(type)} />
      </div>
      <div className='alert-content'>
        {title && (
          <TextTitle className='' level='2'>
            {title}
          </TextTitle>
        )}
        <TextBody level='1'>{message}</TextBody>
      </div>
      <div onClick={onClose}>
        <Icon icon='dismiss-circle-filled' />
      </div>
    </div>
  );
};

export default Alert;
