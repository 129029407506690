import React, { CSSProperties, ReactNode } from "react";
import "./style.scss";

interface NavigationDrawerProps {
  modelValue?: boolean;
  width?: number | string;
  hideOverlay?: boolean;
  children: ReactNode;
  onUpdateModelValue: (value: boolean) => void;
  zIndex?: string;
  zIndexOverlay?: string;
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  modelValue = false,
  width = "300px",
  hideOverlay = false,
  children,
  onUpdateModelValue,
  zIndex,
  zIndexOverlay,
}) => {
  const drawerStyle: CSSProperties = {
    width: width,
    zIndex: zIndex || 2200,
    position: "fixed",
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#fff",
    transition: "transform 0.3s ease-in-out",
    transform: modelValue ? "translateX(0)" : "translateX(110%)",
    maxWidth: "100%",
  };

  const overlayStyle: CSSProperties = {
    zIndex: zIndexOverlay || 2100,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    transition: "opacity 0.3s ease-in-out",
    opacity: modelValue ? 1 : 0,
    visibility: modelValue ? "visible" : "hidden",
  };

  return (
    <>
      <div style={drawerStyle} className='navigation-drawer'>
        {children}
      </div>
      {!hideOverlay && (
        <div
          style={overlayStyle}
          className='navigation-drawer__overlay'
          onClick={() => onUpdateModelValue(false)}
        />
      )}
    </>
  );
};

export default NavigationDrawer;
