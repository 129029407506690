import React from "react";
import { useAnswersList } from "../../../../hooks/feedbacks/useAnswersList";
import TextBody from "../../../common/ui/typography/TextBody";
import Icon from "../../../common/ui/Icon";
import "./style.scss";
import Loader from "../../../common/ui/loader/Loader";
import moment from "moment";

const AnswersList = ({
  questionId,
  setCompanyIdName,
  setCompanyDrawerOpen,
}: {
  questionId: number;
  setCompanyIdName: ({
    id,
    name,
    page,
  }: {
    id: number;
    name: string;
    page: number;
  }) => void;
  setCompanyDrawerOpen: (val: boolean) => void;
}) => {
  const { data, isLoading } = useAnswersList(questionId);
  return (
    <div className='answers'>
      {isLoading ? (
        <Loader />
      ) : (
        data?.map((item: any) => (
          <div
            className='answers__list-item'
            onClick={() => {
              setCompanyIdName({
                id: item.questionnaireId,
                name: item?.company?.name,
                page: 0,
              });
              setCompanyDrawerOpen(true);
            }}
          >
            <div className='answers__list-item__company'>
              <div className='answers__list-item__company__titles'>
                <div className='answers__list-item__company__titles__company-title'>
                  <TextBody level='2'>{item?.company?.name}</TextBody>
                  <div className='answers__list-item__company__titles__company-title__date'>
                    • {moment(item.createdAt).format("HH:mm A, MMM D, YYYY")}
                  </div>
                </div>
                <div className='answers__list-item__company__titles__date'>
                  <TextBody level='1'>{item.note}</TextBody>
                </div>
              </div>
              <Icon
                icon='chevron-right'
                className='answers__list-item__company__icon'
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default AnswersList;
