import React from "react";
import "./radio.scss";
import TextBody from "../ui/typography/TextBody";

interface Option {
  value: string;
  label: string;
}

interface RadioProps {
  option: Option;
  onChange?: (value: string) => void;
  value: string;
  disabled?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  option,
  onChange = () => {},
  value,
  disabled,
}) => {
  return (
    <div className='radioInput' onClick={() => onChange(option.value)}>
      <div
        className={`radio ${value === option.value ? "selectedRadio" : ""}`}
      ></div>
      <div
        className={"radioLabel"}
        style={disabled ? { color: "#9b9daa" } : {}}
      >
        <TextBody level={disabled ? "1" : "2"} className={""}>
          {option.label}
        </TextBody>
      </div>
    </div>
  );
};
