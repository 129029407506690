import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./style.scss";
import InfoCard from "../../../common/ui/infoCard/InfoCard";
import Chart from "../../../common/ui/Chart/Chart";
import SubscriptionQuantity from "./components/SubscriptionQuantity/SubscriptionQuantity";

const CompaniesStatistics: React.FC = () => {
  return (
    <div>
      <div className='d-flex gap-3 w-100'>
        <SubscriptionQuantity />
        <SubscriptionQuantity />
      </div>
    </div>
  );
};

export default CompaniesStatistics;
