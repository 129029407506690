import React, { createContext, useState, useContext, ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";
import Alert from "./Alert";

type AlertType = "success" | "error" | "warning" | "info";

export interface AlertContextProps {
  showAlert: (type: AlertType, message: string, title?: string) => void;
}

export const AlertContext = createContext<AlertContextProps | undefined>(
  undefined,
);

interface AlertProviderProps {
  children: ReactNode;
}

interface AlertItem {
  id: string;
  type: AlertType;
  message: string;
  title?: string;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertItem[]>([]);

  const showAlert = (type: AlertType, message: string, title?: string) => {
    const id = uuidv4();
    setAlerts([...alerts, { id, type, message, title }]);

    setTimeout(() => {
      setAlerts(alerts => alerts.filter(alert => alert.id !== id));
    }, 5000);
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <div className='alert-container'>
        {alerts.map(alert => (
          <Alert
            key={alert.id}
            type={alert.type}
            message={alert.message}
            title={alert.title}
            onClose={() => setAlerts(alerts.filter(a => a.id !== alert.id))}
          />
        ))}
      </div>
    </AlertContext.Provider>
  );
};
