import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import Button from "../../../common/ui/buttons/Button";
import TextBody from "../../../common/ui/typography/TextBody";
import TextTitle from "../../../common/ui/typography/TextTitle";
import DrawerView from "../../../common/ui/navigationDrawer/DrawerView";
import NavigationDrawer from "../../../common/ui/navigationDrawer/NavigationDrawer";
import FeedbackTemplates from "./components/templates";
import templates from "./templates.json";
import TextInput from "../../../common/form/TextInput";
import Icon from "../../../common/ui/Icon";
import { v4 as uuidv4 } from "uuid";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import Checkbox from "../../../common/form/Checkbox";
import FormCard from "./components/form";
import {
  TFeedbackAnswer,
  TFeedbackForm,
  TFeedbackQuestion,
} from "../../../../api/feedbacks/types";
import { feedbacks } from "../../../../api/feedbacks";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../../hooks/useAlert";

const FeedbacksForm: React.FC<{
  editMode?: boolean;
  formQuestions?: TFeedbackForm;
  formId?: undefined | string | number;
}> = ({ editMode, formQuestions, formId }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<TFeedbackForm>(
    formQuestions || {
      title: "",
      description: "",
      key: "",
      questions: [],
    },
  );
  const [disabled, setDisabled] = useState(true);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const chooseTemplate = useCallback((i: number) => {
    setQuestions((prev: TFeedbackForm) => ({
      ...prev,
      questions: [
        ...prev.questions,
        {
          ...(templates[i] as TFeedbackQuestion), // Assuming templates[i] matches Question interface
          uid: uuidv4(),
          answers:
            templates[i].answers &&
            templates[i].answers?.map(el => ({ ...el, uid: uuidv4() })),
        },
      ],
    }));
    setDrawerOpen(false);
  }, []);

  const onChangeTitle = useCallback((title: string) => {
    setQuestions((prev: TFeedbackForm) => ({ ...prev, title }));
  }, []);

  const onChangeDescription = useCallback((description: string) => {
    setQuestions((prev: TFeedbackForm) => ({ ...prev, description }));
  }, []);

  const onChangeKey = useCallback((key: string) => {
    const sanitizedKey = key.replace(/ /g, "-");
    setQuestions((prev: TFeedbackForm) => ({ ...prev, key: sanitizedKey }));
  }, []);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const updatedQuestions = [...questions.questions];
    const [movedQuestion] = updatedQuestions.splice(source.index, 1);
    updatedQuestions.splice(destination.index, 0, movedQuestion);
    setQuestions((prev: TFeedbackForm) => ({
      ...prev,
      questions: updatedQuestions,
    }));
  };
  const toggleOptional = useCallback((index: number) => {
    setQuestions((prev: TFeedbackForm) => ({
      ...prev,
      questions: prev.questions.map(
        (question: TFeedbackQuestion, idx: number) =>
          idx === index
            ? { ...question, isOptional: !question.isOptional }
            : question,
      ),
    }));
  }, []);

  const onDragEndCard = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination || source.index === destination.index) {
        return;
      }

      const updatedQuestions = questions.questions.map(
        (question: TFeedbackQuestion) => {
          if (question.uid === source.droppableId) {
            const updatedAnswers = [...(question.answers as TFeedbackAnswer[])];
            const [removed] = updatedAnswers.splice(source.index, 1);
            updatedAnswers.splice(destination.index, 0, removed);

            return {
              ...question,
              answers: updatedAnswers,
            };
          }
          return question;
        },
      );

      setQuestions({
        ...questions,
        questions: updatedQuestions,
      });
    },
    [questions],
  );
  const onChangeCardField = useCallback(
    (fieldUid: string, optionUid: string, title: string) => {
      const updatedQuestions = questions.questions.map(
        (question: TFeedbackQuestion) => {
          if (question.uid !== fieldUid) return question;

          const updatedAnswers = question.answers?.map(
            (answer: TFeedbackAnswer) => {
              if (answer.uid !== optionUid) return answer;

              return {
                ...answer,
                title,
              };
            },
          );

          return {
            ...question,
            answers: updatedAnswers,
          };
        },
      );

      setQuestions({
        ...questions,
        questions: updatedQuestions,
      });
    },
    [questions],
  );

  const onCopyOption = useCallback(
    (fieldUid: string, optionIndex: number) => {
      setQuestions(prevQuestions => ({
        ...prevQuestions,
        questions: prevQuestions.questions.map(el =>
          el.uid === fieldUid && el.answers
            ? {
                ...el,
                answers: [
                  ...el.answers.slice(0, optionIndex + 1),
                  {
                    ...el.answers[optionIndex],
                    uid: uuidv4(),
                    id: null,
                  },
                  ...el.answers.slice(optionIndex + 1),
                ],
              }
            : el,
        ),
      }));
    },
    [setQuestions],
  );

  const createOption = useCallback(
    (fieldUid: string) => {
      setQuestions(prevQuestions => ({
        ...prevQuestions,
        questions: prevQuestions.questions.map(el =>
          el.uid === fieldUid && el.answers
            ? {
                ...el,
                answers: [...el.answers, { title: "", uid: uuidv4() }],
              }
            : el,
        ),
      }));
    },
    [setQuestions],
  );

  const onDeleteOption = useCallback(
    (fieldUid: string, optionIndex: number) => {
      setQuestions(prevQuestions => ({
        ...prevQuestions,
        questions: prevQuestions.questions.map(el =>
          el.uid === fieldUid && el.answers
            ? {
                ...el,
                answers: el.answers.filter(
                  (_, index: number) => index !== optionIndex,
                ),
              }
            : el,
        ),
      }));
    },
    [setQuestions],
  );

  const onDuplicateQuestion = useCallback(
    (questionIndex: number) => {
      setQuestions((prevQuestions: any) => ({
        ...prevQuestions,
        questions: [
          ...prevQuestions.questions.slice(0, questionIndex + 1),
          {
            ...prevQuestions.questions[questionIndex],
            uid: uuidv4(),
            answers: prevQuestions.questions[questionIndex].answers.map(
              (answer: any) => ({
                ...answer,
                uid: uuidv4(),
                id: null,
              }),
            ),
          },
          ...prevQuestions.questions.slice(questionIndex + 1),
        ],
      }));
    },
    [setQuestions],
  );
  const onDeleteQuestion = useCallback(
    (questionIndex: number) => {
      setQuestions(prevQuestions => ({
        ...prevQuestions,
        questions: prevQuestions.questions.filter(
          (_, index: number) => index !== questionIndex,
        ),
      }));
    },
    [setQuestions],
  );

  useEffect(() => {
    const hasEmptyTitle = questions.questions.some(
      question =>
        !question.title.trim() ||
        (question.answers &&
          question.answers.some(answer => !answer.title.trim())),
    );
    if (
      !questions.questions.length ||
      !questions.title.trim() ||
      !questions.description?.trim() ||
      !questions.key.trim() ||
      hasEmptyTitle
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [questions]);

  const createFeedback = useCallback(async () => {
    try {
      setIsLoading(true);
      await feedbacks.create(questions);
      showAlert("success", "Form successfully created!");
      navigate("/feedbacks");
    } catch (err: any) {
      showAlert(
        "error",
        err?.response?.data?.error.message || "Something went wrong!",
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [questions]);

  const editFeedback = useCallback(async () => {
    try {
      setIsLoading(true);
      await feedbacks.update(questions, formId);
      showAlert("success", "Form successfully created!");
      navigate("/feedbacks");
    } catch (err: any) {
      showAlert(
        "error",
        err?.response?.data?.error.message || "Something went wrong!",
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [formId, questions]);
  return (
    <div className='feedbacksForm'>
      <NavigationDrawer
        modelValue={drawerOpen}
        onUpdateModelValue={() => setDrawerOpen(false)}
        width='1248px'
        zIndex={"2201"}
        zIndexOverlay={"2101"}
      >
        <DrawerView
          closeDrawer={() => setDrawerOpen(false)}
          header={({ closeDrawer }) => (
            <div className={"d-flex align-items-center w-100 gap-3"}>
              <Button
                size={"xs"}
                onClick={closeDrawer}
                beforeIcon={"arrow_left"}
                beforeClass='mr-0'
              />
              <TextTitle level={"1"} className='d-flex align-center gap-2'>
                Feedbacks form templates
              </TextTitle>
            </div>
          )}
        >
          <FeedbackTemplates chooseTemplate={chooseTemplate} />
        </DrawerView>
      </NavigationDrawer>
      <div className='feedbacksForm__header'>
        <TextTitle className=''>
          {editMode ? "Edit" : "New"} feedback form
        </TextTitle>
      </div>
      <div className='feedbacksForm__templates'>
        <div className='feedbacksForm__templates__header'>
          <div className='feedbacksForm__templates__header--title'>
            <TextInput
              onChange={onChangeTitle}
              clearIcon={"dismiss"}
              placeholder='Title'
              title
              textLevel='title-large'
              value={questions.title}
            />
          </div>
          <div className='feedbacksForm__templates__header--description'>
            <TextInput
              onChange={onChangeDescription}
              clearIcon={"dismiss"}
              placeholder='Write description here'
              title
              textLevel='title-3'
              value={questions.description}
            />
          </div>
          <div className='feedbacksForm__templates__header--description'>
            <TextInput
              onChange={onChangeKey}
              clearIcon={"dismiss"}
              placeholder='Write key here'
              title
              textLevel='title-3'
              value={questions.key}
            />
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd} key={"questions"}>
          <Droppable droppableId='questions'>
            {provided => (
              <div
                className='feedbacksForm__templates__list'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questions.questions.map((items, index: number) => (
                  <Draggable
                    key={items.uid}
                    draggableId={items.uid}
                    index={index}
                  >
                    {provided => (
                      <div
                        className='feedbacksForm__templates__template'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className='feedbacksForm__templates__template--header'>
                          <div {...provided.dragHandleProps}>
                            <Icon icon='union' size={"6px"} />
                          </div>
                          <TextInput
                            onChange={(title: string) => {
                              const updatedQuestions = questions.questions.map(
                                (question, idx: number) =>
                                  idx === index
                                    ? { ...question, title }
                                    : question,
                              );
                              setQuestions(prev => ({
                                ...prev,
                                questions: updatedQuestions,
                              }));
                            }}
                            clearIcon={"dismiss"}
                            placeholder='Write your question here...'
                            title
                            textLevel='title-1'
                            value={items.title}
                          />
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                              size={"xs"}
                              onClick={() => onDuplicateQuestion(index)}
                              beforeIcon={"copy"}
                              beforeClass='mr-0'
                            />
                            <Button
                              size={"xs"}
                              onClick={() => onDeleteQuestion(index)}
                              beforeIcon={"delete"}
                              beforeClass='mr-0'
                            />
                          </div>
                        </div>
                        <div className='feedbacksForm__templates__template--body'>
                          <div style={{ marginLeft: "18px" }}>
                            <Checkbox
                              label='* Required'
                              labelSlot={({ label }) => (
                                <TextBody level='1' className='required'>
                                  {label}
                                </TextBody>
                              )}
                              onClick={() => toggleOptional(index)}
                              modelValue={!items.isOptional}
                            />
                          </div>
                          <FormCard
                            onChange={onChangeCardField}
                            templates={[items]}
                            onDragEnd={onDragEndCard}
                            onCopyOption={onCopyOption}
                            onDeleteOption={onDeleteOption}
                          />
                          {(items.answerType === "checkbox" ||
                            items.answerType === "radio") && (
                            <div
                              style={{
                                marginTop: "16px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                              }}
                            >
                              <TextBody level='1'>Add</TextBody>
                              <div>
                                <Button
                                  beforeIcon={"text"}
                                  size='xs'
                                  onClick={() => createOption(items.uid)}
                                >
                                  Text answer
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className='feedbacksForm__addSection'>
        <div className='button-container'>
          <Button
            size='square'
            beforeIcon='add_filled'
            beforeClass='mr-0'
            onClick={() => setDrawerOpen(true)}
          />
        </div>
        <TextBody level='1'>Add a section</TextBody>
      </div>
      <div className='feedbacksForm__footer'>
        <Button size='xs' onClick={() => navigate("/feedbacks")}>
          Cancel
        </Button>
        <Button
          main={!disabled && !isLoading}
          block={disabled || isLoading}
          size='xs'
          onClick={editMode ? editFeedback : createFeedback}
          disabled={disabled || isLoading}
        >
          {editMode ? "Accept" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default FeedbacksForm;
