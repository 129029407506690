import React, { ReactNode } from "react";
import "./style.scss";

interface DrawerViewProps {
  sidebarWidth?: number;
  title?: string;
  loading?: boolean;
  children: ReactNode;
  closeDrawer?: () => void;
  sidebar?: (props: { closeDrawer?: () => void }) => ReactNode;
  header?: (props: { closeDrawer?: () => void }) => ReactNode;
  headerPrepend?: ReactNode;
  headerAppend?: ReactNode;
  footer?: (props: { closeDrawer?: () => void }) => ReactNode;
}

const DrawerView: React.FC<DrawerViewProps> = ({
  sidebarWidth = 312,
  title,
  loading = false,
  children,
  closeDrawer,
  sidebar,
  header,
  headerPrepend,
  headerAppend,
  footer,
}) => {
  const contentHeight =
    (header || title || headerPrepend ? 76 : 0) + (footer ? 64 : 0);

  return (
    <div className='drawer-view'>
      {loading ? (
        <div className='drawer-view__loading'>Loading...</div>
      ) : (
        <>
          {sidebar && (
            <div
              className='drawer-view__sidebar'
              style={{ width: sidebarWidth, flexBasis: sidebarWidth }}
            >
              {sidebar({ closeDrawer })}
            </div>
          )}
          <div className='drawer-view__body'>
            {(header || title || headerPrepend) && (
              <div className='drawer-view__header'>
                <div className='drawer-view__header-content'>
                  {header ? (
                    header({ closeDrawer })
                  ) : (
                    <>
                      {headerPrepend}
                      {title && <h1>{title}</h1>}
                      {headerAppend}
                      <button
                        onClick={closeDrawer}
                        style={{ marginLeft: "5px" }}
                      >
                        Close
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
            <div
              className='drawer-view__content'
              style={{ minHeight: `calc(100% - ${contentHeight}px)` }}
            >
              {children}
            </div>
            {footer && (
              <div className='drawer-view__footer'>
                <div className='drawer-view__footer-content'>
                  {footer({ closeDrawer })}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DrawerView;
