import React, { useEffect, useRef, useState } from "react";
import Icon from "../ui/Icon";
import { TIconsNames } from "../iconSets";
import "./textarea.scss";

type Rule = (v: any) => string | boolean;

interface TextAreaProps {
  modelValue?: string;
  label?: string;
  extraText?: string;
  extraIcon?: TIconsNames;
  beforeIcon?: TIconsNames;
  afterIcon?: TIconsNames;
  clearable?: boolean;
  clearIcon?: TIconsNames;
  placeholder?: string;
  helperClass?: string;
  helperText?: string;
  helperIcon?: TIconsNames;
  type?: "text" | "number" | "password";
  rules?: Rule[];
  disabled?: boolean;
  readonly?: boolean;
  valueSpace?: any;
  hideDetails?: boolean;
  size?: "large" | "sm" | "xs";
  validateWithValueSpace?: boolean;
  onExtraClick?: () => void;
  onClearClick?: () => void;
  onBeforeClick?: () => void;
  onAfterClick?: () => void;
  onHelperClick?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onModelValueChange?: (value: string) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  modelValue = "",
  label,
  extraText,
  extraIcon,
  beforeIcon,
  afterIcon,
  clearable,
  clearIcon = "dismiss",
  placeholder,
  helperClass,
  helperText,
  helperIcon,
  rules = [],
  disabled,
  readonly,
  valueSpace,
  hideDetails,
  size,
  validateWithValueSpace,
  onExtraClick,
  onAfterClick,
  onHelperClick,
  onFocus,
  onBlur,
  onModelValueChange,
}) => {
  const [focused, setFocused] = useState(false);
  const [checkingCounter, setCheckingCounter] = useState(0);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (checkingCounter > 0) {
      check();
    }
  }, [modelValue, valueSpace, checkingCounter]);

  const getFirstMessageFromRules = () => {
    const validatingValue = !validateWithValueSpace ? modelValue : valueSpace;
    for (const rule of rules) {
      const ruleValue = rule(validatingValue);
      if (typeof ruleValue === "string") {
        return ruleValue;
      }
    }
    return "";
  };

  const firstRuleMessage = getFirstMessageFromRules();

  const onInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onModelValueChange?.(event.target.value);
    setCheckingCounter(prev => prev + 1);
  };

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    onFocus?.(e);
    setFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    onBlur?.(e);
    setFocused(false);
    setCheckingCounter(prev => prev + 1);
  };

  const handleClear = () => {
    inputRef.current?.focus();
    onModelValueChange?.("");
    setCheckingCounter(prev => prev + 1);
  };

  const check = () => {
    // Any additional validation logic can be added here if needed
  };

  return (
    <div
      className={`text-area ${focused ? "text-area--focus" : ""} ${firstRuleMessage ? "text-area--error" : ""} ${disabled ? "text-area--disabled" : ""} ${readonly ? "text-area--readonly" : ""} ${size ? `text-area--${size}` : ""}`}
    >
      {(label || extraText || extraIcon) && (
        <div className='text-area__top'>
          {label && (
            <div
              onClick={() => inputRef.current?.focus()}
              className='text-area__label'
            >
              {label}
            </div>
          )}
          <div onClick={onExtraClick} className='text-area__extra'>
            {extraIcon && <Icon icon={extraIcon} size={16} />}
            <div className='text-area__extra-text'>{extraText}</div>
          </div>
        </div>
      )}
      <div className='text-area__field'>
        {beforeIcon && (
          <div className='text-area__before'>
            <Icon icon={beforeIcon} size={20} />
          </div>
        )}
        <div className='text-area__value-space'>{valueSpace}</div>
        <textarea
          value={modelValue}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          onInput={onInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          className='text-area__input'
        />
        {clearable && (modelValue || valueSpace) && (
          <div onClick={handleClear} className='text-area__after'>
            <Icon icon={clearIcon} size={20} className='text-area__clear' />
          </div>
        )}
        {afterIcon && (
          <div onClick={onAfterClick} className='text-area__after'>
            <Icon icon={afterIcon} size={20} />
          </div>
        )}
      </div>
      {!hideDetails && (
        <div className='text-area__bottom'>
          {helperText ? (
            <div
              onClick={onHelperClick}
              className={`text-area__helper ${helperClass}`}
            >
              {helperIcon && (
                <Icon
                  icon={helperIcon}
                  size={16}
                  className='text-area__helper-icon'
                />
              )}
              {helperText}
            </div>
          ) : firstRuleMessage ? (
            <div className='text-area__helper text-area__rule-message'>
              {firstRuleMessage}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TextArea;
