import axiosInstance from "../../axios";

interface LoginCredentials {
  username: string;
  password: string;
}

interface LoginResponse {
  token: string;
}

export const authAdmin = {
  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    try {
      const response = await axiosInstance.post<LoginResponse>(
        "/auths/login/super-admin",
        credentials,
      );
      const { token } = response.data;

      localStorage.setItem("accessToken", token);

      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  },
  me: async () => {
    try {
      const response = await axiosInstance.get("/users/me");

      return response.data;
    } catch (error) {
      console.error("Me error:", error);
      throw error;
    }
  },
};
