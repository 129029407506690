import React, { useState, useRef, useEffect, ReactNode } from "react";
import "./style.scss"; // Make sure to import your SCSS styles here

interface MenuProps {
  activator: ({ onClick }: { onClick: () => void }) => ReactNode;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const CustomMenu: React.FC<MenuProps> = ({
  activator,
  isOpen,
  onClose,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(isOpen);
  const [menuPosition, setMenuPosition] = useState<"bottom" | "top">("bottom");
  const menuRef = useRef<HTMLDivElement>(null);
  const activatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMenuOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        activatorRef.current &&
        !activatorRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen, onClose]);

  const handleActivatorClick = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      onClose();
    }
    calculateMenuPosition();
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.relatedTarget as Node) &&
      activatorRef.current &&
      !activatorRef.current.contains(event.relatedTarget as Node)
    ) {
      setMenuOpen(false);
      onClose();
    }
  };

  const calculateMenuPosition = () => {
    if (activatorRef.current && menuRef.current) {
      const activatorRect = activatorRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (activatorRect.bottom + menuRect.height + 100 > windowHeight) {
        setMenuPosition("top");
      } else {
        setMenuPosition("bottom");
      }
    }
  };

  useEffect(() => {
    calculateMenuPosition();
  }, []);

  return (
    <div
      className={`menu-wrap ${menuPosition}`}
      ref={menuRef}
      tabIndex={-1}
      onBlur={handleBlur}
    >
      <div className='dropdown' ref={activatorRef}>
        {activator({ onClick: handleActivatorClick })}
      </div>
      {menuOpen && <div className='menu'>{children}</div>}
    </div>
  );
};

export default CustomMenu;
