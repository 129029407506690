import React from "react";
import "./style.scss";
import TextBody from "../typography/TextBody";

interface ProgressBarProps {
  percentage: number;
  color: "red" | "blue";
  beforeText?: string;
  afterText?: string;
  beforeLevel?: "large" | "1" | "2" | "3" | "4";
  afterLevel?: "large" | "1" | "2" | "3" | "4";
  beforeWidth?: string;
  afterWidth?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  color,
  beforeText,
  afterText,
  beforeLevel,
  afterLevel,
  beforeWidth,
  afterWidth,
}) => {
  return (
    <div className='progress-bar-container'>
      {beforeText && (
        <span
          className={`progress-bar-text ${color}-bar_text`}
          style={{ width: beforeWidth || "auto" }}
        >
          <TextBody className={""} level={beforeLevel || "1"}>
            {beforeText}
          </TextBody>
        </span>
      )}
      <div className='progress-bar'>
        <div
          className={`progress-bar-fill ${color}-bar`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      {afterText && (
        <span
          className={`progress-bar-text ${color}-bar_text`}
          style={{ width: afterWidth || "auto" }}
        >
          <TextBody className={""} level={afterLevel || "1"}>
            {afterText}
          </TextBody>
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
