import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
  ReactNode,
} from "react";
import "./style.scss";
interface TabsContextType {
  registerTab: (id: string) => void;
  unregisterTab: (id: string) => void;
  selectTab: (id: string) => void;
  activeTab: string;
}

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsProps {
  modelValue?: number;
  onTabChange?: (index: number) => void;
  children: ReactNode;
}

export const Tabs: React.FC<TabsProps> = ({
  modelValue,
  onTabChange,
  children,
}) => {
  const [tabs, setTabs] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>("");

  const registerTab = useCallback((id: string) => {
    setTabs(prev => [...prev, id]);
  }, []);

  const unregisterTab = useCallback((id: string) => {
    setTabs(prev => prev.filter(tab => tab !== id));
  }, []);

  const selectTab = useCallback(
    (id: string) => {
      setActiveTab(id);
      const index = tabs.indexOf(id);
      if (index !== -1 && onTabChange) {
        onTabChange(index);
      }
    },
    [tabs, onTabChange],
  );

  useEffect(() => {
    if (modelValue !== undefined && tabs.length > 0) {
      setActiveTab(tabs[modelValue]);
    }
  }, [modelValue, tabs]);

  return (
    <TabsContext.Provider
      value={{ registerTab, unregisterTab, selectTab, activeTab }}
    >
      <div className='tabs'>{children}</div>
    </TabsContext.Provider>
  );
};

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a Tabs");
  }
  return context;
};
