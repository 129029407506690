import { useInfiniteQuery } from "react-query";
import { feedbacks } from "../../api/feedbacks";

export const useAnswersList = (questionId: number) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, ...rest } =
    useInfiniteQuery({
      queryKey: ["feedbacks-answers", questionId],
      queryFn: ({ pageParam = 1 }) =>
        feedbacks.listAnswers(questionId, pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.reduce(
          (acc, page) => acc + page.data.length,
          0,
        );
        const totalAvailable = lastPage?.count ?? 0;
        return totalFetched < totalAvailable ? allPages.length + 1 : undefined;
      },
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    });

  const combinedData = data?.pages?.flatMap(page => page.data) ?? [];

  return {
    data: combinedData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...rest,
  };
};
