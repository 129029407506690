import React from "react";
import { useForm, Controller } from "react-hook-form";
import "./style.scss";
import Button from "../common/ui/buttons/Button";
import TextInput from "../common/form/TextInput";
import { emailRule } from "../common/validationRules";
import { PasswordInput } from "../common/form/PasswordInput";
import useLogin from "../../hooks/auth/useLogin";

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginForm>();
  const { login } = useLogin();

  return (
    <div className='auth-container'>
      <form onSubmit={handleSubmit(login)} className='auth-card'>
        <h1 className='mb-6'>Sign in</h1>
        <div className='mb-3'>
          <Controller
            control={control}
            name='email'
            rules={{
              required: "Email is required",
              validate: emailRule,
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <TextInput
                    onChange={field.onChange}
                    value={field.value}
                    state={error ? "error" : ""}
                    helperText={error?.message}
                    clearable
                    clearIcon={"dismiss"}
                    label={"Email"}
                  />
                </>
              );
            }}
          />
        </div>
        <div className='mb-3'>
          <Controller
            control={control}
            name='password'
            rules={{
              required: "Password is required",
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <PasswordInput
                    onChange={field.onChange}
                    value={field.value}
                    state={error ? "error" : ""}
                    helperText={error?.message}
                    showPassword={true}
                    clearable
                    clearIcon={"dismiss"}
                    label={"password"}
                  />
                </>
              );
            }}
          />
        </div>

        <Button type={"submit"} main disabled={isSubmitting}>
          {isSubmitting ? "Signing in..." : "Sign in"}
        </Button>
        <div className='divider' />
      </form>
    </div>
  );
};

export default Login;
