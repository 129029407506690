import React, { ReactNode, useEffect, useMemo } from "react";
import { useTabsContext } from "./Tabs";
import { uniqueId } from "lodash";
import Icon from "../Icon";
import { TIconsNames } from "../../iconSets";

interface TabProps {
  icon?: TIconsNames;
  activeIcon?: TIconsNames;
  text?: string;
  children?: ReactNode;
}

export const Tab: React.FC<TabProps> = ({
  icon,
  activeIcon,
  text,
  children,
}) => {
  const { registerTab, unregisterTab, selectTab, activeTab } = useTabsContext();
  const id = useMemo(() => uniqueId(), []);

  useEffect(() => {
    registerTab(id);
    return () => {
      unregisterTab(id);
    };
  }, [id, registerTab, unregisterTab]);

  const isActive = activeTab === id;

  const handleClick = () => {
    selectTab(id);
  };

  return (
    <div
      className={`tab ${isActive ? "tab--active" : ""}`}
      onClick={handleClick}
    >
      {icon && (
        <Icon
          icon={isActive && activeIcon ? activeIcon : icon}
          className='tab__icon'
        />
      )}
      {text && <div className='tab__text'>{text}</div>}
      {children}
    </div>
  );
};
