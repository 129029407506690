import { useQuery } from "react-query";
import { authAdmin } from "../../api/auth";

export const useAuth = () => {
  return useQuery(["user"], authAdmin.me, {
    staleTime: 1000 * 60 * 5,
    retry: false,
    refetchOnWindowFocus: false,
  });
};
