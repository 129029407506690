import { useCallback, useRef } from "react";

type FetchNextPageFunction = (options?: { pageParam?: number }) => Promise<any>;

interface UseInfiniteScrollOptions {
  fetchNextPage: FetchNextPageFunction;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
}

const useInfiniteScroll = (options: UseInfiniteScrollOptions) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = options;
  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading || !node) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      });

      observer.current.observe(node);

      return () => {
        if (observer.current) observer.current.disconnect();
      };
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading],
  );

  return lastElementRef;
};

export default useInfiniteScroll;
