import React, { useCallback } from "react";
import "./style.scss";
import { useFeedbackCompanyAnswer } from "../../../../hooks/feedbacks/useFeedbackCompanyAnswer";
import Checkbox from "../../../common/form/Checkbox";
import { Radio } from "../../../common/form/Radio";
import TextInput from "../../../common/form/TextInput";
import Loader from "../../../common/ui/loader/Loader";
import TextTitle from "../../../common/ui/typography/TextTitle";
import TextArea from "../../../common/form/Textarea";

const CompanyAnswer = ({ companyId }: { companyId: number }) => {
  const { data, isLoading } = useFeedbackCompanyAnswer(companyId);

  const renderField = useCallback((field: any, i: number) => {
    switch (field.answerType) {
      case "checkbox":
        return (
          <div key={i} className='d-flex flex-column gap-3 answer'>
            <TextTitle className='' level='1'>
              {field.title}{" "}
              {!field.isOptional && (
                <>
                  * <div className={"required"}>(Required)</div>
                </>
              )}
            </TextTitle>

            <div className='d-flex flex-column' style={{ gap: "12px" }}>
              {field.answers?.map((option: any, index: number) =>
                option.title === "other" ? (
                  <React.Fragment key={index}>
                    <Checkbox
                      modelValue={option.isMarked}
                      label='Other'
                      disabled={!option.isMarked}
                    />
                    <div style={{ marginLeft: 30 }}>
                      <TextInput
                        type='text'
                        value={option.note || ""}
                        readonly
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <Checkbox
                      key={index}
                      modelValue={option.isMarked}
                      label={option.title}
                      disabled={!option.isMarked}
                    />
                  </React.Fragment>
                ),
              )}
            </div>
          </div>
        );
      case "radio":
        return (
          <div key={i} className={`d-flex flex-column gap-3 answer`}>
            <TextTitle className='' level='1'>
              {field.title}{" "}
              {!field.isOptional && (
                <>
                  * <div className={"required"}>(Required)</div>
                </>
              )}
            </TextTitle>
            <div
              className={`d-flex ${field.isHorizontal ? "flex-row" : "flex-column"}`}
              style={{ gap: "12px" }}
            >
              {field.answers?.map((option: any, index: number) => (
                <Radio
                  key={index}
                  option={{ value: option.title, label: option.title }}
                  value={option.isMarked ? option.title : ""}
                  disabled={!option.isMarked}
                />
              ))}
            </div>
          </div>
        );
      case "input":
        return (
          <div key={i} className='d-flex flex-column gap-3 answer'>
            <TextTitle className='' level='1'>
              {field.title}{" "}
              {!field.isOptional && (
                <>
                  * <div className={"required"}>(Required)</div>
                </>
              )}
            </TextTitle>
            <TextInput type='text' value={field.note || ""} readonly />
          </div>
        );
      case "textarea":
        return (
          <div key={i} className='d-flex flex-column gap-3 answer'>
            <TextTitle className='' level='1'>
              {field.title}{" "}
              {!field.isOptional && (
                <>
                  * <div className={"required"}>(Required)</div>
                </>
              )}
            </TextTitle>
            <TextArea type='text' modelValue={field.note || ""} readonly />
          </div>
        );
      default:
        return null;
    }
  }, []);

  return (
    <div className='answer_container'>
      {isLoading ? (
        <Loader />
      ) : (
        <form className='dynamic-form d-flex flex-column'>
          {data?.data?.map(renderField)}
        </form>
      )}
    </div>
  );
};

export default CompanyAnswer;
