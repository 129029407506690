import React, { useState, useEffect } from "react";
import { isArray } from "lodash";
import Icon from "../ui/Icon";
import "./checkbox.scss";
import TextBody from "../ui/typography/TextBody";

interface CheckboxProps<T> {
  modelValue?: T;
  name?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  value?: boolean;
  label?: string;
  type?: "main" | "purple";
  onModelValueUpdate?: (value: T, e: Event) => void;
  onIndeterminateUpdate?: (value: boolean, e: Event) => void;
  onClick?: (e: React.MouseEvent) => void;
  labelSlot?: (props: { label?: string }) => React.ReactNode;
}

const Checkbox = <T extends unknown>({
  modelValue,
  name,
  disabled,
  indeterminate,
  value = true,
  label,
  type = "main",
  onModelValueUpdate,
  onClick,
  labelSlot,
}: CheckboxProps<T>) => {
  const [inputVal, setInputVal] = useState<boolean | undefined>();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    if (isArray(modelValue)) {
      const emittingValue = modelValue.includes(value)
        ? modelValue.filter(item => item !== value)
        : [...modelValue, value];
      onModelValueUpdate &&
        onModelValueUpdate(emittingValue as T, event as unknown as Event);
    } else {
      onModelValueUpdate &&
        onModelValueUpdate(
          (input.checked ? value : !value) as T,
          event as unknown as Event,
        );
    }
  };

  useEffect(() => {
    if (modelValue !== undefined) {
      if (isArray(modelValue)) {
        setInputVal(modelValue.includes(value));
      } else {
        setInputVal(modelValue === value);
      }
    }
  }, [modelValue, value]);

  return (
    <label className='checkbox'>
      <input
        checked={inputVal || indeterminate}
        onChange={onChange}
        name={name}
        value={String(value)}
        onClick={e => onClick && onClick(e)}
        type='checkbox'
        disabled={disabled}
      />
      <div className={`checkbox__box checkbox--${type}`}>
        {modelValue && (
          <div className='checkbox__icon'>
            {(isArray(modelValue)
              ? modelValue.includes(value)
              : !!modelValue) && (
              <Icon icon='checkbox' size={12} color='white' />
            )}
          </div>
        )}
      </div>
      {(label || labelSlot) && (
        <div
          className='checkbox__label'
          style={disabled ? { color: "#9B9DAA" } : {}}
        >
          <TextBody className={""} level={disabled ? "1" : "2"}>
            {labelSlot ? labelSlot({ label }) : label}
          </TextBody>
        </div>
      )}
    </label>
  );
};

export default Checkbox;
