import React, { useState } from "react";
import "./style.scss";

interface ButtonGroupTabProps {
  options: { title: string; value: string }[];
  defaultActive: { title: string; value: string };
  onOptionSelect?: (selectedOption: { title: string; value: string }) => void;
}

const ButtonGroupTab: React.FC<ButtonGroupTabProps> = ({
  options,
  defaultActive,
  onOptionSelect,
}) => {
  const [activeOption, setActiveOption] = useState<{
    title: string;
    value: string;
  }>(defaultActive);

  const handleButtonClick = (option: { title: string; value: string }) => {
    setActiveOption(option);
    if (onOptionSelect) {
      onOptionSelect(option);
    }
  };

  return (
    <div className='button-group-tab'>
      {options.map(option => (
        <button
          key={option.value}
          className={`button-tab ${activeOption.value === option.value ? "active" : ""}`}
          onClick={() => handleButtonClick(option)}
        >
          {option.title}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroupTab;
