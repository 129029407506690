import { isArray } from "lodash";

export type RuleFunctionType<S = string | number | boolean> = (
  v: S,
) => boolean | string;

export const requiredRule =
  (field: string) => (v: string | number | boolean | string[] | number[]) => {
    if (isArray(v)) {
      return v.length > 0 || `${field} is required`;
    }
    if (typeof v === "string") {
      return /\S/.test(v) || `${field} is required`;
    } else {
      return !!v || `${field} is required`;
    }
  };

export const percentRule: RuleFunctionType<number> = percent =>
  (percent >= 0.1 && percent <= 100) || "Percentage should be 0.1 to 100";

export const emailRule: RuleFunctionType<string> = email =>
  /.+@.+\..+/.test(email) || "E-mail must be valid";

export const minLengRule = (
  leng: number,
  field: string,
): RuleFunctionType<string> => {
  return v =>
    v?.length >= leng || `${field} must be at least ${leng} characters`;
};

export const maxLengRule = (
  leng: number,
  field: string,
): RuleFunctionType<string> => {
  return v => v?.length <= leng || `${field} can not exceed ${leng} characters`;
};

export const passwordRule: RuleFunctionType<string> = password => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
  return (
    pattern.test(password) ||
    "Minimum 6 characters, One capital letter, Special symbol, Number"
  );
};

export const phoneRule: RuleFunctionType<string> = phone =>
  /^\+?998\s?\d{2}\s?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/.test(phone) ||
  "Phone must be valid";

export const confirmRule =
  (confirmValue: string | number, message: string) => (v: string | number) =>
    v === confirmValue || message;

export const notNegative =
  (field: string): RuleFunctionType =>
  v =>
    Number(v) >= 0 || `${field} must not be negative`;

export const validVIN = (value: string) =>
  /^[A-HJ-NPR-Z0-9]{17}$/.test(value) || "Invalid VIN";
