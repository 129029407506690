import { useQuery, useMutation, useQueryClient } from "react-query";
import { feedbacks } from "../../api/feedbacks";
import { TFeedback } from "../../api/feedbacks/types";
import { useAlert } from "../useAlert";

export const useFeedbacks = (isActive: boolean) => {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const { data, isLoading } = useQuery(
    ["feedbacks", isActive],
    () => feedbacks.list(isActive),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const deactivateMutation = useMutation(
    async (formId: number) => {
      const { message } = await feedbacks.deactivate(formId);
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        queryClient.invalidateQueries(["feedbacks"]);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  const reactivateMutation = useMutation(
    async (formId: number) => {
      const { message } = await feedbacks.reactivate(formId);
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        queryClient.invalidateQueries(["feedbacks"]);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  const removeMutation = useMutation(
    async (formId: number) => {
      const { message } = await feedbacks.remove(formId);
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        queryClient.invalidateQueries(["feedbacks"]);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  return {
    data: data
      ? data.data.map((el: TFeedback) => {
          const name = el.title;
          const added_date = el.createdAt;
          const added_by = el.addedBy.first_name + " " + el.addedBy.second_name;
          return {
            name,
            added_date,
            added_by,
            statistics: el.statistics,
            id: el.id,
          };
        })
      : [],
    isLoading,
    deactivate: deactivateMutation.mutate,
    reactivate: reactivateMutation.mutate,
    remove: removeMutation.mutate,
  };
};
