import dock_left from "./icons/dock_left.svg";
import view_desktop from "./icons/view_desktop.svg";
import view_desktop_filled from "./icons/view_desktop_filled.svg";
import comment from "./icons/comment.svg";
import comment_filled from "./icons/comment_filled.svg";
import info from "./icons/info.svg";
import plus from "./icons/plus.svg";
import arrow_clockwise from "./icons/arrow_clockwise.svg";
import text_bullet_list_square from "./icons/text_bullet_list_square.svg";
import text_bullet_list_square_filled from "./icons/text_bullet_list_square_filled.svg";
import archive from "./icons/archive.svg";
import archive_filled from "./icons/archive_filled.svg";
import more_filled from "./icons/more_filled.svg";
import dismiss from "./icons/dismiss.svg";
import data_histogram from "./icons/data_histogram.svg";
import data_histogram_filled from "./icons/data_histogram_filled.svg";
import eye_hide from "./icons/eye_hide.svg";
import eye_hide_filled from "./icons/eye_hide_filled.svg";
import eye_show from "./icons/eye_show.svg";
import eye_show_filled from "./icons/eye_show_filled.svg";
import mail from "./icons/mail.svg";
import mail_filled from "./icons/mail_filled.svg";
import lock from "./icons/lock.svg";
import lock_filled from "./icons/lock_filled.svg";
import archive_red from "./icons/archive-red.svg";
import archive_blue from "./icons/archive-blue.svg";
import edit from "./icons/edit.svg";
import chevron_right from "./icons/chevron_right.svg";
import checkbox from "./icons/checkbox.svg";
import arrow_left from "./icons/arrow_left.svg";
import add_filled from "./icons/add_filled.svg";
import union from "./icons/union.svg";
import copy from "./icons/copy.svg";
import remove from "./icons/delete.svg";
import text from "./icons/text.svg";
import checkmark_circle_filled from "./icons/checkmark_circle_filled.svg";
import error_circle_filled from "./icons/error_circle_filled.svg";
import error_triangle_filled from "./icons/error_triangle_filled.svg";
import info_filled from "./icons/info_filled.svg";
import dismiss_circle_filled from "./icons/dismiss_circle_filled.svg";
import chevron_down from "./icons/chevron_down.svg";
import calendar from "./icons/calendar.svg";
// @ts-ignore
export const icons = {
  "dock-left": dock_left,
  "view-desktop": view_desktop,
  "view-desktop-filled": view_desktop_filled,
  comment: comment,
  "comment-filled": comment_filled,
  info: info,
  plus: plus,
  "arrow-clockwise": arrow_clockwise,
  "text-bullet-list-square": text_bullet_list_square,
  "text-bullet-list-square-filled": text_bullet_list_square_filled,
  archive: archive,
  "archive-filled": archive_filled,
  more_filled: more_filled,
  dismiss: dismiss,
  "data-histogram": data_histogram,
  "data-histogram-filled": data_histogram_filled,
  "eye-hide-filled": eye_hide_filled,
  "eye-hide": eye_hide,
  "eye-show-filled": eye_show_filled,
  "eye-show": eye_show,
  mail: mail,
  "mail-filled": mail_filled,
  lock: lock,
  "lock-filled": lock_filled,
  "archive-red": archive_red,
  "archive-blue": archive_blue,
  edit: edit,
  "chevron-right": chevron_right,
  checkbox: checkbox,
  arrow_left: arrow_left,
  add_filled: add_filled,
  union: union,
  copy,
  delete: remove,
  text,
  "checkmark-circle-filled": checkmark_circle_filled,
  "error-triangle-filled": error_triangle_filled,
  "error-circle-filled": error_circle_filled,
  "info-filled": info_filled,
  "dismiss-circle-filled": dismiss_circle_filled,
  "chevron-down": chevron_down,
  calendar,
} as const;

export type TIconsNames = `${keyof typeof icons}`;
